
import React, { useState, useEffect } from "react";
import { RouteComponentProps } from "@reach/router";

import {
  List, ListItem, ListItemText, Divider,
  DialogContent, Typography,
  Drawer
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";

import Table from "ui/Table";
import { PageHeader } from "ui/Header";
import { InlinedChip } from "ui/Custom";
import Dialog from 'ui/Dialog';

import { Sheet } from '__types__';

import { formatDate } from "utils";

import { COLORS, useStyles } from 'constants/styles';
import { SectorDataRowsColumns } from './data';
import Paper from "ui/Paper";
import "styled-components/macro";

export interface DefaultPageProps extends RouteComponentProps {
  noPageTitle?: boolean;
}

const defaultValues = {
  name: '',
}
export const Categories: React.FC<DefaultPageProps> = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openDelete, setOpenDelete] = React.useState(false);
  const [data, setData] = React.useState<Sheet[]>([]);
  const [formValues, setFormValues] = React.useState(defaultValues);
  const [isFormSubmited, setFormSubmited] = React.useState(false);
  const [isSnackbarOpen, setIsSnackbarOpen] = React.useState(false);
  const [current, setCurrent] = React.useState(null);

  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [picture, setPicture] = React.useState(null);


  useEffect(() => {
    let timeoutInfoMessage: any;

    if (errorMessage) {
      timeoutInfoMessage = setTimeout(() => setErrorMessage(null), 4000);
    }

    if (successMessage) {
      timeoutInfoMessage = setTimeout(() => setSuccessMessage(null), 1500);
    }

    return () => {
      clearTimeout(timeoutInfoMessage);
    };
  }, [errorMessage, successMessage]);


  React.useEffect(() => {
    let timeoutInfoMessage: any;

    if (isSnackbarOpen) {
      timeoutInfoMessage = setTimeout(() => setIsSnackbarOpen(false), 6000);
    }

    return () => {
      clearTimeout(timeoutInfoMessage);
    };
  }, [isSnackbarOpen]);


  React.useEffect(() => {
    fetch('/api/v1/studies/messages')
      .then(res => res.json())
      .then((data) => {
        setData(data)
      })
      .catch(console.log)
  }, []);

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleDelete = () => {
    const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' }
    };
    fetch('/api/v1/messages/' + current.id, requestOptions)

      .then((res) => {
        setData(prev => prev.filter((item) => item.id !== current.id))
        setOpenDelete(false);
        setSuccessMessage('Categorie supprimer avec succès');
        setCurrent(null);
      })
      .catch(error => {
        setErrorMessage('Erreur');
        console.error('There was an error!', error);
      });
  }


  function closeDrawer() {
    setIsDrawerOpen(false)
  }

  function onDrop(picture: any) {
    setPicture(picture[0])
  }


  return (
    <>
      <PageHeader title="Demande d'information">
      </PageHeader>
      <Paper>
        <Table
          rowsIdentifier='id'
          columns={SectorDataRowsColumns}
          data={data}
          onRowClick={(row) => {
            setCurrent(row);
            setIsDrawerOpen(true);
          }}
          onRowDelete={(row) => {
            setCurrent(row);
            setOpenDelete(true);
          }}
        />
      </Paper>


      <Dialog
        title="Suppression"
        confirmButtonTitle="Supprimer"
        open={openDelete}
        handleConfirm={handleDelete}
        handleClose={handleCloseDelete}>
        <DialogContent>
          Veuillez confirmer la suppression
        </DialogContent>
      </Dialog>

      <Drawer
        className={classes.drawer}
        anchor="right"
        open={isDrawerOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
        onClose={closeDrawer}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={closeDrawer}>
            <CloseIcon />
          </IconButton>
        </div>
        <List dense>
          <ListItem>
            <ListItemText
              primary={
                <Typography variant="h3">
                  <b style={{ marginRight: 10 }}>{current?.name}</b>
                  <InlinedChip
                    label={current?.createdAt && formatDate(current?.createdAt, "LLL dd, yyyy · HH:mm")}
                    backgroundColor={COLORS['blueFocus']}
                    size="small"
                  />
                </Typography>
              }
            />
          </ListItem>

          <Divider />

          <ListItem>
            {current?.message}
          </ListItem>

        </List>
      </Drawer>

    </>
  );
};

export default Categories;
